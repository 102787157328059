import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import PageSectionHeader from 'ui-kit/page-section-header/page-section-header';

import { ListingsLayoutProps } from 'components/layouts/listings/listings.props';
import PageLayout from 'components/layouts/page/page.layout';

import withSessionExpiration from 'hoc/withSessionExpiration';

import { paragraphToComponent } from 'providers/paragraphs/paragraphs';

import './listings.style.scss';

const ListingsLayout = ({
    children,
    eyebrowText,
    title,
    subTitle,
    heading,
    suppressChildrenContainer
}: ListingsLayoutProps) => {
    const replacePath = (path: string) => (path === `/` ? path : path.replace(/\/$/, ``));
    const pageLocation = replacePath(useLocation().pathname);

    const layoutData = useStaticQuery(graphql`
        query {
            blueSkyBackground: file(relativePath: { eq: "assets/images/feather-background.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(formats: [AUTO])
                }
            }
            allNodeApplicationPage {
                nodes {
                    title
                    id
                    drupal_internal__nid
                    relationships {
                        field_application_page_content {
                            ...supportedParagraphs
                        }
                    }
                    path {
                        langcode
                        alias
                    }
                }
            }
        }
    `);

    // See if there is any Drupal Application Page content that matches this page URL
    const applicationPageParagraphs = layoutData.allNodeApplicationPage.nodes.filter(
        (node: any) => pageLocation === node.path?.alias?.toString()
    );

    return (
        <PageLayout
            headerImage={layoutData.blueSkyBackground}
            headerImageClassName="listings-background"
            pageContentClassName="listings-wrapper"
            metaData={{ nodeTitle: title }}
            suppressApplicationPage={true}
        >
            <div className="listings">
                <Container fluid="xl">
                    <Row className="justify-content-lg-center">
                        <Col xs={12} md={12} lg={8}>
                            <PageSectionHeader eyebrowText={eyebrowText} title={title} subTitle={subTitle} />
                        </Col>
                    </Row>
                    <Row className="no-gutters">
                        <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                            <div
                                className={
                                    !suppressChildrenContainer
                                        ? `profile-active-menu-item-${heading ? 'bottom-' : ''}wrapper`
                                        : ''
                                }
                            >
                                {children}
                            </div>
                            {applicationPageParagraphs.length > 0 && (
                                <div className="mt-5">
                                    {applicationPageParagraphs[0]?.relationships?.field_application_page_content.map(
                                        (paragraph: any, index: number) => (
                                            <React.Fragment key={`application_page_section_${index}`}>
                                                {paragraphToComponent(paragraph?.internal.type, paragraph, index + 10)}
                                            </React.Fragment>
                                        )
                                    )}
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </PageLayout>
    );
};

export default withSessionExpiration(ListingsLayout);
